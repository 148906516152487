<template>
  <PageDefault :isLoading="isLoading">
    <template v-slot:content>
      <section v-if="!loaded" class="mt-4 has-text-centered">
        <div class="card">
          <div class="card-content">
            <p>Cargando...</p>
          </div>
        </div>
      </section>

      <section class="order-success-header" v-if="order.status == 'finished' && loaded">
        <div class="message">
          <p class="is-size-4" align="center" translate>
            Tu compra ha sido realizada con éxito
          </p>
          <div class="ref">
            <label class="label">
              <translate>Referencia de operación</translate>
              :
              <span class="reference">{{ order.lookup_ref }}</span>
            </label>
          </div>
          <p v-if="hasTickets">
            <translate
              class="ma-0"
              :translate-n="numTickets"
              translate-plural="Para acceder al recinto es necesario llevar los códigos QR en tu dispositivo móvil o bien imprimir las entradas que te hemos enviado a la dirección de email:"
            >
              Para acceder al recinto es necesario llevar el código QR en tu dispositivo
              móvil o bien imprimir la entrada que te hemos enviado a la dirección de
              email:
            </translate>
            <span class="user-email">{{ order.email }}.</span>
          </p>
          <translate>Revisa tu buzón de correo.</translate>
        </div>
        <section class="download-section" v-if="hasTickets">
          <div
            class="download-button button button-2406 is-info py-3 px-4"
            @click="throttledDownload"
          >
            <b-icon icon="download" class="mr-3" />
            <translate :translate-n="numTickets" translate-plural="Descargar entradas">
              Descargar entrada
            </translate>
          </div>
        </section>
        <OrderSummary :order="order" />
      </section>
    </template>
  </PageDefault>
</template>

<script>
import _ from "lodash"
import OrderMixin from "@/mixins/OrderMixin"
import OrderSummary from "@/components/sections/OrderSummary"
import { injectScript } from "@/utils/script-injector"

export default {
  name: "OrderSuccess",
  mixins: [OrderMixin],
  components: { OrderSummary },
  data() {
    return {
      orderDocumentURL: null,
      throttledDownload: _.throttle(this.downloadPDF, 2000),
      gtmNotified: false,
    }
  },
  computed: {
    numTickets() {
      return this.order ? _.size(this.order.tickets) : 0
    },
    hasTickets() {
      return _.some(
        this.order?.order_lines_summary,
        (line) => line.zone_type == "standard"
      )
    },
  },
  methods: {
    async printOrderTickets() {
      return this.$api
        .printOrderTickets(this.order.lookup_ref)
        .then((orderDocumentUrl) => {
          this.orderDocumentURL = orderDocumentUrl
        })
    },
    downloadPDF() {
      window.location = this.orderDocumentURL
    },
    checkOrder() {
      if (this.order.status != "finished") {
        this.$router.push({
          name: "order-error",
          params: { lookup_ref: this.order.lookup_ref },
          query: this.$defaultQuery(),
        })
      } else {
        this.printOrderTickets()
      }
    },
    pushGTMData() {
      if (this.$env.gtm_id && this.$gtm && this.$gtm.enabled()) {
        if (this.order.tickets.length == 0) {
          return
        }
        this.$gtm.trackEvent({ ecommerce: null })
        let products = []
        let fees = 0
        let taxes = 0
        let coupon = ""

        if (this.order.discount_coupon) {
          coupon = this.order.discount_coupon.code
        }

        _.each(this.order.tickets_payment, (ticket) => {
          taxes += parseFloat(ticket.tax)
          fees += parseFloat(ticket.fee)
        })
        products.push({
          name: this.order.event.name,
          id: this.order.event.lookup_ref,
          price: parseFloat(
            this.order.total_amount / this.order.tickets.length
          ).toFixed(2),
          category: "Evento",
          variant: this.order.event.place.name,
          quantity: this.order.tickets.length,
          dimension1: this.order.event.place.municipality,
          dimension2: this.order.tickets[0].start_date,
          dimension3: this.order.tickets[0].start_time,
          dimension5: this.order.tickets.length,
          dimension6: "",
          dimension7: coupon,
          dimension8: "No",
        })
        this.$gtm.trackEvent({
          event: "purchase",
          eventCategory: "purchase",
          eventLabel: this.$route.params.checkoutType,
          ecommerce: {
            purchase: {
              actionField: {
                id: this.order.lookup_ref,
                revenue: this.order.total_amount,
                tax: taxes.toFixed(2),
                shipping: fees.toFixed(2),
                coupon: coupon,
              },
              products: products,
            },
          },
        })
      }
    },
    getOrder() {
      this.$api.getOrderOk(this.$route.params.lookup_ref).then(
        (response) => {
          this.order = response
          this.loaded = true
          this.pushGTMData()

          if (!this.gtmNotified) {
            this.$store.dispatch("gtm2404/purchase", { order: this.order })
            this.gtmNotified = true
          }

          const scriptContent = this.order.event.thank_you_page_injected_script

          if (!_.isEmpty(scriptContent) && _.isString(scriptContent)) {
            injectScript(scriptContent)
          }
        },
        () => {
          this.loaded = true
        }
      )
    },
  },
  watch: {
    "order.lookup_ref"(value) {
      if (!value) return
      this.checkOrder()
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.order-success-header
  .is-size-4
    font-weight: $weight-semibold
  .label
    color: darken($success, 33%)
  .reference
    font-weight: $weight-semibold
  .user-email
    color: darken($success, 33%)
    font-weight: $weight-semibold
    font-size: 1.12rem
  @include from($tablet)
    margin: 3rem 0
  @include until($tablet)
    margin: 1.5rem 0

.message
  background: $success
  color: darken($success, 33%)
  padding: 1rem

.download-section
  @include from($mobile)
    margin: 2rem 0
    display: flex
    justify-content: space-between
    align-items: flex-start
  @include until($mobile)
    margin: 1rem 0

  .download-button
    @include until($mobile)
      width: 100%
      margin: 1rem 0 2rem 0
</style>
